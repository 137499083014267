import request from "lib/request";
import config from "config/app";
import lib from "lib/commons";
import lang from "lng/index";
const lng = lang[config.lng];

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_REQUEST_ERROR = "CURRENT_USER_REQUEST_ERROR";
export const CURRENT_USER_REQUEST_SUCCESS = "CURRENT_USER_REQUEST_SUCCESS";
export const CURRENT_USER_FETCHING = "CURRENT_USER_FETCHING";

export const SPIN = "SPIN";
export const SPIN_ERROR = "SPIN_ERROR";
export const SPIN_SUCCESS = "SPIN_SUCCESS";
export const IS_SPINNING = "IS_SPINNING";

export const SHARE = "SHARE";
export const SHARE_ERROR = "SHARE_ERROR";
export const SHARE_SUCCESS = "SHARE_SUCCESS";
export const IS_SHARING = "IS_SHARING";

export const SHARE_LIVESTREAM = "SHARE_LIVESTREAM";
export const SHARE_LIVESTREAM_ERROR = "SHARE_LIVESTREAM_ERROR";
export const SHARE_LIVESTREAM_SUCCESS = "SHARE_LIVESTREAM_SUCCESS";
export const IS_SHARING_LIVESTREAM = "IS_SHARING_LIVESTREAM";

export const CHECK_MATCH = "CHECK_MATCH";
export const CHECK_MATCH_ERROR = "CHECK_MATCH_ERROR";
export const CHECK_MATCH_SUCCESS = "CHECK_MATCH_SUCCESS";
export const IS_CHECKING_MATCH = "IS_CHECKING_MATCH";

export const VOTE = "VOTE";
export const VOTE_ERROR = "VOTE_ERROR";
export const VOTE_SUCCESS = "VOTE_SUCCESS";
export const IS_VOTING = "IS_VOTING";

export const VOTE_MATCH = "VOTE_MATCH";
export const VOTE_MATCH_ERROR = "VOTE_MATCH_ERROR";
export const VOTE_MATCH_SUCCESS = "VOTE_MATCH_SUCCESS";
export const IS_VOTING_MATCH = "IS_VOTING_MATCH";

export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_ERROR = "GET_HISTORY_ERROR";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const IS_GETTING_HISTORY = "IS_GETTING_HISTORY";

export const GET_ANSWER_HISTORY = "GET_ANSWER_HISTORY";
export const GET_ANSWER_HISTORY_ERROR = "GET_ANSWER_HISTORY_ERROR";
export const GET_ANSWER_HISTORY_SUCCESS = "GET_ANSWER_HISTORY_SUCCESS";
export const IS_GETTING_ANSWER_HISTORY = "IS_GETTING_ANSWER_HISTORY";

export const CLAIM = "CLAIM";
export const CLAIM_ERROR = "CLAIM_ERROR";
export const CLAIM_SUCCESS = "CLAIM_SUCCESS";
export const IS_CLAIMING = "IS_CLAIMING";

export const EXCHANGE = "EXCHANGE";
export const EXCHANGE_ERROR = "EXCHANGE_ERROR";
export const EXCHANGE_SUCCESS = "EXCHANGE_SUCCESS";
export const IS_EXCHANGING = "IS_EXCHANGING";

export const REGISTER = "REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const IS_REGISTERING = "IS_REGISTERING";

export const ANSWER = "ANSWER";
export const ANSWER_ERROR = "ANSWER_ERROR";
export const ANSWER_SUCCESS = "ANSWER_SUCCESS";
export const IS_ANSWERING = "IS_ANSWERING";

let tm;

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING,
  };
};

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request("api/user/get").then(function (response) {
      if (response.status == "successful") {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if (response.error_code == "no_account") {
          lib.showDownlad();
        }
        dispatch(getCurrentUserError(response));
      }
    });
  };
};

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload,
  };
};

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload,
  };
};

//Spin
export const isSpinning = (response) => {
  return {
    type: IS_SPINNING,
  };
};
export const spin = (setWinningItem) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request("api/user/spin", "POST", {
      body: JSON.stringify({}),
    }).then(function (response) {
      if (response.status == "successful") {
        let items = getState().currentUser.spinPrize;
        let itemArray = [];
        for (let index = 0; index < items.length; index++) {
          const item = items[index];

          itemArray.push(item.id);
        }

        let winItem = response.payload.reward;

        let shuffledArray = lib.shuffleArray([...itemArray]);
        let currentIndex = 0;
        let turns = 0;
        let maxTurn = itemArray.length == 1 ? 1 : 6;

        tm = setInterval(() => {
          if (currentIndex == shuffledArray.length) {
            currentIndex = 0;
          }
          if (turns >= maxTurn && shuffledArray[currentIndex] == winItem.id) {
            clearInterval(tm);
            $(`.item-cover--${winItem.id}`).css("animation", "won 1.5s");
            setTimeout(() => {
              $(`.item-cover--${winItem.id}`).css("animation", "none");
              lib
                .showMessage(
                  `Bạn đã nhận được:<br><div class="item-cover"><img src="${winItem.image}" alt="" class="item-cover__img" /></div><p>${winItem.product_name}</p>`,
                  null,
                  "Chúc mừng!"
                )
                .then((res) => {
                  dispatch(spinSuccess(response));
                });
            }, 1500);
          }

          setWinningItem(shuffledArray[currentIndex]);
          currentIndex++;
          turns++;
        }, 400);
      } else {
        lib.showError(response.error_code);
        dispatch(spinError(response));
      }
    });
  };
};
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload,
  };
};
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload,
  };
};

//Share
export const isSharing = (response) => {
  return {
    type: IS_SHARING,
  };
};
export const share = (shareCustom = null) => {
  return (dispatch, getState) => {
    dispatch(isSharing());
    request("api/user/share", "POST", {
      body: JSON.stringify({}),
    }).then(function (response) {
      if (response.status == "successful") {
        if (shareCustom) {
          shareCustom();
        } else {
          lib.showMessage('<p class="text-center">Chia sẻ thành công!</p>');
        }
        dispatch(shareSuccess(response));
      } else {
        lib.showError(response.error_code);
        dispatch(shareError(response));
      }
    });
  };
};
export const shareSuccess = (response) => {
  return {
    type: SHARE_SUCCESS,
    payload: response.payload,
  };
};
export const shareError = (response) => {
  return {
    type: SHARE_ERROR,
    payload: response.payload,
  };
};

//Select
export const isCheckingMatch = (response) => {
  return {
    type: IS_CHECKING_MATCH,
  };
};
export const checkMatch = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingMatch());
    request("api/user/update-daily", "POST", {
      body: JSON.stringify({}),
    }).then(function (response) {
      if (response.status == "successful") {
        lib
          .showMessage(
            `
          <div class="row match-progress">
            <div class="col-5">
              <p>Hoàn thành trận</p>
            </div>
            <div class="col-7">
              <img src="/images/play${
                response.payload.play_normal
              }.png" alt="" class="play-progress" />
            </div>
            <div class="w-100"></div>
            <div class="col-5">
              <p>Share Facebook</p>
            </div>
            <div class="col-7">
              <img src="/images/share${
                response.payload.shared ? 1 : 0
              }.png" alt="" class="play-progress-share" />
            </div>
          </div>
        `,
            null,
            "Tiến độ nhiệm vụ",
            null
          )
          .then((res) => {
            dispatch(checkMatchSuccess(response));
          });
      } else {
        lib.showError(response.error_code);
        dispatch(checkMatchError(response));
      }
    });
  };
};
export const checkMatchSuccess = (response) => {
  return {
    type: CHECK_MATCH_SUCCESS,
    payload: response.payload,
  };
};
export const checkMatchError = (response) => {
  return {
    type: CHECK_MATCH_ERROR,
    payload: response,
  };
};

//Vote
export const isVoting = (response) => {
  return {
    type: IS_VOTING,
  };
};
export const vote = (team) => {
  return (dispatch, getState) => {
    dispatch(isVoting());
    request("api/user/vote-team", "POST", {
      body: JSON.stringify({
        team_id: parseInt(team.id),
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib.showMessage("Bạn đã chọn thành công " + team.team);
        dispatch(voteSuccess(response));
      } else {
        lib.showError(response.error_code);
        dispatch(voteError(response));
      }
    });
  };
};
export const voteSuccess = (response) => {
  return {
    type: VOTE_SUCCESS,
    payload: response.payload,
  };
};
export const voteError = (response) => {
  return {
    type: VOTE_ERROR,
    payload: response,
  };
};

//Vote
export const isVotingMatch = (response) => {
  return {
    type: IS_VOTING_MATCH,
  };
};
export const voteMatch = (matchId, result, star, token) => {
  return (dispatch, getState) => {
    dispatch(isVoting());
    request("api/user/vote", "POST", {
      body: JSON.stringify({
        match_id: parseInt(matchId),
        result: parseInt(result),
        star: parseInt(star),
        point: parseInt(token),
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib.showMessage("Bạn đã bình chọn trận đấu thành công");
        dispatch(voteMatchSuccess(response));
      } else {
        lib.showError(response.error_code);
        dispatch(voteMatchError(response));
      }
    });
  };
};
export const voteMatchSuccess = (response) => {
  return {
    type: VOTE_MATCH_SUCCESS,
    payload: response.payload,
  };
};
export const voteMatchError = (response) => {
  return {
    type: VOTE_MATCH_ERROR,
    payload: response,
  };
};

//Claim
export const isSharingLivestream = (response) => {
  return {
    type: IS_SHARING_LIVESTREAM,
  };
};
export const shareLivestream = (shareCustom = null) => {
  return (dispatch, getState) => {
    dispatch(isSharingLivestream());
    request("api/user/share-stream", "POST", {
      body: JSON.stringify({}),
    }).then(function (response) {
      if (response.status == "successful") {
        if (shareCustom) {
          shareCustom();
        } else {
          lib.showMessage('<p class="text-center">Chia sẻ thành công!</p>');
        }
        dispatch(shareLivestreamSuccess(response));
      } else {
        lib.showError(response.error_code);
        dispatch(shareLivestreamError(response));
      }
    });
  };
};
export const shareLivestreamSuccess = (response) => {
  return {
    type: SHARE_LIVESTREAM_SUCCESS,
    payload: response.payload,
  };
};
export const shareLivestreamError = (response) => {
  return {
    type: SHARE_LIVESTREAM_ERROR,
    payload: response.payload,
  };
};

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY,
  };
};

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request("api/user/history").then(function (response) {
      if (response.status == "successful") {
        dispatch(getHistorySuccess(response));
      } else {
        dispatch(getHistoryError(response));
      }
    });
  };
};

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload,
  };
};

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload,
  };
};

//get answer history
export const isGettingAnswerHistory = () => {
  return {
    type: IS_GETTING_ANSWER_HISTORY,
  };
};

export const getAnswerHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingAnswerHistory());
    request("api/user/answer-history").then(function (response) {
      if (response.status == "successful") {
        dispatch(getAnswerHistorySuccess(response));
      } else {
        dispatch(getAnswertHistoryError(response));
      }
    });
  };
};

export const getAnswerHistorySuccess = (response) => {
  return {
    type: GET_ANSWER_HISTORY_SUCCESS,
    payload: response.payload,
  };
};

export const getAnswertHistoryError = (response) => {
  return {
    type: GET_ANSWER_HISTORY_ERROR,
    payload: response.payload,
  };
};

//Claim
export const isClaiming = (response) => {
  return {
    type: IS_CLAIMING,
  };
};
export const claim = (prize) => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request("api/user/claim", "POST", {
      body: JSON.stringify({
        stage: parseInt(prize.stage),
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib.showMessage(
          `<p class="text-center">Bạn nhận được ${prize.point} điểm</p>`,
          null,
          "Chúc mừng"
        );
        dispatch(claimSuccess(response));
      } else {
        lib.showError(response.error_code);
        dispatch(claimError(response));
      }
    });
  };
};
export const claimSuccess = (response) => {
  return {
    type: CLAIM_SUCCESS,
    payload: response.payload,
  };
};
export const claimError = (response) => {
  return {
    type: CLAIM_ERROR,
    payload: response.payload,
  };
};

//Exchange
export const isExchanging = (response) => {
  return {
    type: IS_EXCHANGING,
  };
};
export const exchange = (item) => {
  return (dispatch, getState) => {
    dispatch(isExchanging());
    request("api/user/exchange", "POST", {
      body: JSON.stringify({
        exchange_item_id: parseInt(item.exchange_item_id),
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib
          .showMessage(
            `Bạn đã nhận được:<br><div class="item-cover"><img src="${item.image}" alt="" class="item-cover__img" /></div><p>${item.desc}</p>`,
            null,
            "Chúc mừng!"
          )
          .then((res) => {
            dispatch(exchangeSuccess(response));
          });
      } else {
        lib.showError(response.error_code);
        dispatch(exchangeError(response));
      }
    });
  };
};
export const exchangeSuccess = (response) => {
  return {
    type: EXCHANGE_SUCCESS,
    payload: response.payload,
  };
};
export const exchangeError = (response) => {
  return {
    type: EXCHANGE_ERROR,
    payload: response,
  };
};

//Register
export const isRegistering = (response) => {
  return {
    type: IS_REGISTERING,
  };
};
export const register = (data, closeModal) => {
  return (dispatch, getState) => {
    dispatch(isRegistering());
    request("api/user/register", "POST", {
      body: JSON.stringify({
        address: data.address,
        name: data.name,
        phone_number: data.phone_number,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib
          .showMessage(
            `Bạn đã đăng ký thành công<br>Chúng tôi sẽ sớm liên hệ với bạn!`,
            null,
            "Chúc mừng!"
          )
          .then((res) => {
            closeModal();
            dispatch(registerSuccess(response));
          });
      } else {
        lib.showError(response.error_code);
        dispatch(registerError(response));
      }
    });
  };
};
export const registerSuccess = (response) => {
  return {
    type: REGISTER_SUCCESS,
    payload: response.payload,
  };
};
export const registerError = (response) => {
  return {
    type: REGISTER_ERROR,
    payload: response,
  };
};

//Answer
export const isAnswering = (response) => {
  return {
    type: IS_ANSWERING,
  };
};
export const answer = (question_id, choices, closeModal) => {
  return (dispatch, getState) => {
    dispatch(isAnswering());
    request("api/user/answer", "POST", {
      body: JSON.stringify({
        question_id: question_id,
        choices: choices,
      }),
    }).then(function (response) {
      if (response.status == "successful") {
        lib
          .showMessage(`Bạn đã trả lời câu hỏi thành công!`, null, "Chúc mừng!")
          .then((res) => {
            closeModal();
            dispatch(answerSuccess(response));
          });
      } else {
        lib.showError(response.error_code);
        dispatch(answerError(response));
      }
    });
  };
};
export const answerSuccess = (response) => {
  return {
    type: ANSWER_SUCCESS,
    payload: response.payload,
  };
};
export const answerError = (response) => {
  return {
    type: ANSWER_ERROR,
    payload: response,
  };
};
