import config from "config/app";
import lib from "lib/commons";

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,
  SHARE_ERROR,
  SHARE_SUCCESS,
  IS_SHARING,
  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,
  CHECK_MATCH_ERROR,
  CHECK_MATCH_SUCCESS,
  IS_CHECKING_MATCH,
  VOTE_ERROR,
  VOTE_SUCCESS,
  IS_VOTING,
  VOTE_MATCH_ERROR,
  VOTE_MATCH_SUCCESS,
  IS_VOTING_MATCH,
  SHARE_LIVESTREAM_ERROR,
  SHARE_LIVESTREAM_SUCCESS,
  IS_SHARING_LIVESTREAM,
  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  IS_GETTING_HISTORY,
  GET_ANSWER_HISTORY_ERROR,
  GET_ANSWER_HISTORY_SUCCESS,
  IS_GETTING_ANSWER_HISTORY,
  CLAIM_ERROR,
  CLAIM_SUCCESS,
  IS_CLAIMING,
  EXCHANGE_ERROR,
  EXCHANGE_SUCCESS,
  IS_EXCHANGING,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  IS_REGISTERING,
  ANSWER_ERROR,
  ANSWER_SUCCESS,
  IS_ANSWERING,
} from "./actions";

const initialState = {
  loading: false,
  login: false,
  user: {},
  items: {},
  matches: [],
  totalShare: 0,
  teams: [],
  eventStarted: 0,
  streamUrl: "",
  currentTime: null,
  playerPieces: [],
  spinPrize: [],
  topPlayers: [],
  serverPrizes: [],
  imageTable: "",
  imageLeagueTable: "",
  imageBracket: "",
  limitHour: null,
  userHistory: [],
  delta: 0,
  change_exchange_datetime: null,
  exchange_prizes: [],
  questions: [],
  userAnswerHistory: [],

  isSpinning: false,
  isCheckingMatch: false,
  isSharing: false,
  isVoting: false,
  isSharingLivestream: false,
  isGettingHistory: false,
  isGettingAnswerHistory: false,
  isClaiming: false,
  isExchanging: false,
  isRegistering: false,
  isAnswering: false,
};

export default function currentUserReducer(
  state = initialState,
  { type, payload } = action
) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        matches: payload.matches,
        totalShare: payload.total_share,
        teams: payload.teams,
        eventStarted: payload.event_started,
        streamUrl: payload.stream_url,
        currentTime: payload.current_datetime,
        playerPieces: payload.player_pieces,
        spinPrize: payload.spin_prize,
        topPlayers: payload.top,
        serverPrizes: payload.server_prizes,
        imageTable: payload.image_table,
        imageLeagueTable: payload.image_league_table,
        imageBracket: payload.image_bracket,
        limitHour: payload.stream_share_limit_hour,
        change_exchange_datetime: payload.change_exchange_datetime,
        delta:
          new Date(lib.convertDateForIos(payload.current_datetime)).getTime() -
          new Date().getTime(),
        exchange_prizes: payload.exchange_prizes,
        questions: payload.questions,
        errorGlobal: "",
      };
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        matches: payload.matches,
        totalShare: payload.total_share,
        teams: payload.teams,
        eventStarted: payload.event_started,
        streamUrl: payload.stream_url,
        currentTime: payload.current_datetime,
        playerPieces: payload.player_pieces,
        spinPrize: payload.spin_prize,
        topPlayers: payload.top,
        serverPrizes: payload.server_prizes,
        imageTable: payload.image_table,
        imageLeagueTable: payload.image_league_table,
        imageBracket: payload.image_bracket,
        limitHour: payload.stream_share_limit_hour,
        change_exchange_datetime: payload.change_exchange_datetime,
        delta:
          new Date(lib.convertDateForIos(payload.current_datetime)).getTime() -
          new Date().getTime(),
        exchange_prizes: payload.exchange_prizes,
        questions: payload.questions,
        errorGlobal: "Không có thông tin user",
      };
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true,
      };
      break;

    //CLAIM
    case SPIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          spin_times: payload.user.spin_times,
        },
        isSpinning: false,
      };
      break;
    case SPIN_ERROR:
      return {
        ...state,
        isSpinning: false,
      };
      break;
    case IS_SPINNING:
      return {
        ...state,
        isSpinning: true,
      };
      break;

    //SHARE
    case SHARE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user_vote: payload.user.user_vote,
          spin_times: payload.user.spin_times,
          total_spin_times: payload.user.total_spin_times,
          answer_tickets: payload.user.answer_tickets,
          point: payload.user.point,
        },
        isSharing: false,
      };
      break;
    case SHARE_ERROR:
      return {
        ...state,
        isSharing: false,
      };
      break;
    case IS_SHARING:
      return {
        ...state,
        isSharing: true,
      };
      break;

    //SHARE LIVESTREAM
    case SHARE_LIVESTREAM_SUCCESS:
      return {
        ...state,
        totalShare: payload.user.total_share,
        isSharingLivestream: false,
      };
      break;
    case SHARE_LIVESTREAM_ERROR:
      return {
        ...state,
        isSharingLivestream: false,
      };
      break;
    case IS_SHARING_LIVESTREAM:
      return {
        ...state,
        isSharingLivestream: true,
      };
      break;

    //CHECK MATCH
    case CHECK_MATCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          point: payload.user.point,
          spin_times: payload.user.spin_times,
          answer_tickets: payload.user.answer_tickets,
        },
        isCheckingMatch: false,
      };
      break;
    case CHECK_MATCH_ERROR:
      return {
        ...state,
        isCheckingMatch: false,
      };
      break;
    case IS_CHECKING_MATCH:
      return {
        ...state,
        isCheckingMatch: true,
      };
      break;

    //VOTE
    case VOTE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          team_id: payload.user.team_id,
        },
        isVoting: false,
      };
      break;
    case VOTE_ERROR:
      return {
        ...state,
        isVoting: false,
      };
      break;
    case IS_VOTING:
      return {
        ...state,
        isVoting: true,
      };
      break;

    //VOTE MATCH
    case VOTE_MATCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user_vote: payload.user.user_vote,
          point: payload.user.point,
          vote_history: payload.user.vote_history,
        },
        matches: state.matches.map((match) =>
          match.id == payload.match.id
            ? {
                ...match,
                ...payload.match,
              }
            : match
        ),
        isVoting: false,
      };
      break;
    case VOTE_MATCH_ERROR:
      return {
        ...state,
        isVoting: false,
      };
      break;
    case IS_VOTING_MATCH:
      return {
        ...state,
        isVoting: true,
      };
      break;

    //GET HISTORY
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload,
        isGettingHistory: false,
      };
      break;
    case GET_HISTORY_ERROR:
      return {
        ...state,
        isGettingHistory: false,
      };
      break;
    case IS_GETTING_HISTORY:
      return {
        ...state,
        isGettingHistory: true,
      };
      break;

    //GET ANSWER HISTORY
    case GET_ANSWER_HISTORY_SUCCESS:
      return {
        ...state,
        userAnswerHistory: payload,
        isGettingAnswerHistory: false,
      };
      break;
    case GET_ANSWER_HISTORY_ERROR:
      return {
        ...state,
        isGettingAnswerHistory: false,
      };
      break;
    case IS_GETTING_ANSWER_HISTORY:
      return {
        ...state,
        isGettingAnswerHistory: true,
      };
      break;

    //CLAIM
    case CLAIM_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          server_prize_history: payload.user.server_prize_history,
          spin_times: payload.user.spin_times,
          total_spin_times: payload.user.total_spin_times,
        },
        isClaiming: false,
      };
      break;
    case CLAIM_ERROR:
      return {
        ...state,
        isClaiming: false,
      };
      break;
    case IS_SHARING:
      return {
        ...state,
        isClaiming: true,
      };
      break;

    //EXCHANGE
    case EXCHANGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          exchange_prize_history: payload.user.exchange_prize_history,
          need_register: payload.user.need_register,
          point: payload.user.point,
        },
        server_prizes: payload.server_prizes,
        exchange_prizes: payload.exchange_prizes,
        isExchanging: false,
      };
      break;
    case EXCHANGE_ERROR:
      return {
        ...state,
        isExchanging: false,
      };
      break;
    case IS_EXCHANGING:
      return {
        ...state,
        isExchanging: true,
      };
      break;

    //REGISTER
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          need_register: 0,
          name: payload.user.name,
          phone_number: payload.user.phone_number,
          address: payload.user.address,
        },
        isRegistering: false,
      };
      break;
    case REGISTER_ERROR:
      return {
        ...state,
        isRegistering: false,
      };
      break;
    case IS_REGISTERING:
      return {
        ...state,
        isRegistering: true,
      };
      break;

    //ANSWER
    case ANSWER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          answer_tickets: payload.user.answer_tickets,
          answers: payload.user.date_answers,
        },
        isAnswering: false,
      };
      break;
    case ANSWER_ERROR:
      return {
        ...state,
        isAnswering: false,
      };
      break;
    case IS_ANSWERING:
      return {
        ...state,
        isAnswering: true,
      };
      break;

    default:
      return state;
  }
}
